import { autoinject, LogManager, bindable } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Logger } from 'aurelia-logging';

import * as L from 'leaflet';
import { RouteState } from 'components/admin/admin-constants/index';
import { AlertModel, Alert } from 'components/common/dialog/alert';
import { AdminRouteHistoryService } from 'components/admin/services/admin-route/admin-route-history-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { IRoute } from 'components/admin/services/admin-route/models/route-interface';
import { IRouteHistory } from 'components/admin/services/admin-route/models/route-history.interface';
import { AdminHistoryEventItemType } from 'components/admin/admin-constants/event-types/admin-history-event-item-type.enum';

@autoinject()
export class RouteHistory {
    @bindable route: IRoute;

	loading = false;
	routeHistory: IRouteHistory[];
	expanded = false;

    private logger: Logger;
    private routeLayerInMap: L.Proj.GeoJSON;
    private map: L.Map;

    constructor(
        private applicationRepository: ApplicationRepository,
        private dialogService: DialogService,
        private eventAggregator: EventAggregator,
        private adminRouteHistoryService: AdminRouteHistoryService) {

        this.logger = LogManager.getLogger('RouteHistory');
    }

    attached() {
        this.map = this.applicationRepository.map;
        this.getHistory();
    }

    detached() {
        this.removeRouteFromMap();
    }

    toggle() {
        this.expanded = !this.expanded;
        this.logger.debug('Expanded: ', this.expanded);
    }

    toggleItem(event, historyItem): void {
        event.stopPropagation();
        historyItem.expanded = !historyItem.expanded;

        this.logger.debug('Item expanded: ', historyItem.expanded);
    }

    toggleShowRoute(event, historyItem: IRouteHistory): void {
        event.stopPropagation();

        const currentState = historyItem["showRoute"];
        this.clearShowRouteState();
        historyItem["showRoute"] = currentState ? false : true;

        if (historyItem["showRoute"] && historyItem.Route) {
            this.drawRouteInMap(historyItem.Route);
        }
        else {
            this.removeRouteFromMap();
        }
    }

    recreateRoute(event, historyItem: IRouteHistory): void {
        event.stopPropagation();
		
		if (historyItem.Route) {

            const alertModel: AlertModel = {
				Mode: "question",
				ErrorMessage: "Vill du återskapa leden?",
                ExtraInfo: "Leden återskapas som en arbetsversion.\nDet går fortfarande att rutta på leden i produktion.",
				AbortText: "Avbryt",
				ConfirmText: "Återskapa led"
            };

            this.dialogService.open({ viewModel: Alert, model: alertModel }).whenClosed(response => {
                if (!response.wasCancelled) {
					historyItem.Route.FeatureOid = this.route.FeatureOid;
					historyItem.Route.RouteState = RouteState.Utcheckad;
                    this.eventAggregator.publish(AdminHistoryEventItemType.RECREATE_SELECTED_HISTORY, historyItem.Route);
                }
            });
        }
	}
	
	private clearShowRouteState(): void {
        if (this.routeHistory) {
            this.routeHistory.forEach(x => x["showRoute"] = false);
        }
    }

    private async getHistory(): Promise<void> {
        if (this.route) {
            this.loading = true;
            try {
                this.routeHistory = await this.adminRouteHistoryService.getHistory(this.route.FeatureOid, this.route.RouteType);
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
                this.logger.error(error);
            }
        }
    }

    private removeRouteFromMap(): void {
        if (!this.map) {
            return;
        }

        if (this.routeLayerInMap) {
            this.routeLayerInMap.removeFrom(this.map);
            this.routeLayerInMap = null;
        }
    }

    private drawRouteInMap(route: IRoute): void {
        this.removeRouteFromMap();

        if (!route || !route.Geometry) {
            return;
        }

        const geom: any[] = [];
        for (let index = 0; index < route.Geometry.length; index++) {
            const mappedGeom = route.Geometry[index].map(x => [x.X, x.Y]);
            geom.push(mappedGeom);
        }

        const geojson: Proj4GeoJSONFeature = {
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: geom
            },
            crs: {
                type: 'name',
                properties: {
                    name: 'urn:ogc:def:crs:EPSG::3006'
                }
            },
            properties: {
                color: '#777777',
                weight: 6
            }
        };

        this.routeLayerInMap = L.Proj.geoJson(geojson, {
            style: (feature: any) => {
                return {
                    color: feature.properties.color,
                    weight: feature.properties.weight
                };
            }
        });

        this.routeLayerInMap.addTo(this.map);

        //zoom to layer
        if (this.routeLayerInMap) {
            this.map.fitBounds(this.routeLayerInMap.getBounds().pad(Math.sqrt(2) / 2));
        }
    }
}
