import { DirectionMap } from "components/admin/services/admin-route/admin-route-service";
import { Direction } from "components/admin/services/admin-route/models/direction.enum";

export class RouteDirectionFormatValueConverter {
    toView(value: number) {
        if (DirectionMap.DirectionName.has(value)) {
            return DirectionMap.DirectionName.get(value);
		}
		
		return DirectionMap.DirectionName.get(Direction.In);
    }
}