import { autoinject, computedFrom, bindable, bindingMode } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import moment from 'moment';
import * as L from 'leaflet';
import { RouteType } from 'components/admin/admin-constants/index';
import { IRouteInformationWithMerge } from './route-card/route-card';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { StateService } from 'services/state-service';
import { SearchType } from 'services/place-search/models/search-type.enum';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { PlaceSearchService } from 'services/place-search/place-search-service';
import { IPlaceSearchItem } from 'services/place-search/models/place-search-item.interface';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { IRouteInformation } from 'services/application-repository/models/route-information.interface';
import { IMapRoutePoint } from 'services/application-repository/models/map-route-point.interface';
import { MapConfiguration } from 'services/map-configuration/map-configuration';
import { IVehicleDefinition } from 'services/routing/models/vehicle-definition.interface';
import { RoutingService } from 'services/routing/routing-service';
import { IRoutingLocation } from 'services/routing/models/routing-location.interface';
import { ICalculatedRouteRequest } from 'services/routing/models/calculated-route-request.interface';
import { ICalculatedRoute } from 'services/routing/models/calculated-route.interface';
import { INetworkReferencesWithAction } from 'components/admin/services/admin-route/models/network-references-with-action.interface';
import { IRoute } from 'components/admin/services/admin-route/models/route-interface';
import { IFacitRoute } from 'components/admin/services/admin-route/models/facit-route.interface';
import { AdminRouteService } from 'components/admin/services/admin-route/admin-route-service';
import { NetworkReferenceAction } from 'components/admin/services/admin-route/models/network-reference-action.enum';
import { AdminRouteEventItemType } from 'components/admin/admin-constants/event-types/admin-route-event-type.enum';
import { IconService } from 'services/assets/services/icon.service';
import { IconType } from 'services/assets/enums/icon-type.enum';

@autoinject()
export class Route {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) route: IRoute;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) validation: { isPristine: boolean; isValid: boolean };
	thisVM = this;
	expanded = true;
	routeInformations: IRouteInformationWithMerge[]; // Holds calculated routes
	routeInformation: IRouteInformation; // Holds calculated route (Facitrutt)

	calculatingRoute: boolean;
	routeBtnEnabled: boolean;
	vehicleDefinitions: IVehicleDefinition[];
	vehicleDefinition: IVehicleDefinition;
	startLocation: IMapRoutePoint;
	endLocation: IMapRoutePoint;
	viaLocations: IMapRoutePoint[] = [];
	private originalNetworkReferences: INetworkReferencesWithAction;
	private routeLayerInMap: L.Proj.GeoJSON;
	private viaMarkers: L.Marker[] = [];
	private startMarker: L.Marker;
	private endMarker: L.Marker;

	private routers: { id: string; value: string; data: any; }[];
	private router: { id: string; value: string; data: any; };

	private map: L.Map;
	private mapCrs;
	private routeColorsCount = 1;

	private zoomToRouteSubscription: Subscription;
	private toggleShowRouteSubscription: Subscription;
	private mapLocationSelectedSubscription: Subscription;

	private isViol3Mode: boolean;

	@computedFrom('viaLocations.length')
	get canAddViaLocation() {
		return this.canAddViaPoints();
	}

	@computedFrom('route.RouteType')
	get isFacitRutt(): boolean {
		if (!this.route) {
			return false;
		}

		return this.route.RouteType === RouteType.FacitRutt;
	}

	@computedFrom('route.Geometry')
	get endLocationText() {
		if (this.route && this.route.Geometry) {
			if (this.route.RouteType == RouteType.FacitRutt) {
				const facitRoute = this.route as IFacitRoute;
				return facitRoute.PickupLocationName + ' (' + facitRoute.PickupLocationNumber + ')';
			}
		}
		return '';
	}

	@computedFrom('routeBtnEnabled')
	get disableRemoval(): boolean {
		if (!this.route.FeatureOid) {
			//disable when new route
			return true;
		} else {
			return !this.routeBtnEnabled;
		}
	}

	constructor(
		private applicationRepo: ApplicationRepository,
		private mapConfig: MapConfiguration,
		private placeSearchService: PlaceSearchService,
		private routingService: RoutingService,
		private adminRouteService: AdminRouteService,
		private stateService: StateService,
		private eventAggregator: EventAggregator,
		private config: EnvironmentConfiguration,
		private iconService: IconService) {

		const toggleConfig = config.env.FeatureToggle;
		this.isViol3Mode = toggleConfig.IsViol3;
		this.stateService.getState('TEST_ROUTE_VVDL')
			.then((data: any) => {
				if (data) {
					const date = moment(data.savedAt).format('YYYYMMDD HH:mm:ss');
					// Some one has stored state for route_vvdl before
					this.routers = [
						{ id: 'PRODUKTION', value: 'Standard', data: 'Standard' },
						{ id: 'TEST', value: 'Provruttning', data: `${data.userName} (${date})` }
					];

					this.router = this.routers[0];
				}
				else {
					this.routers = undefined;
					this.router = undefined;
				}
			});

	}

	async attached() {
		this.map = this.applicationRepo.map;
		this.mapCrs = this.applicationRepo.mapCrs;
		this.applicationRepo.canAddViaPoint = this.canAddViaPoints;
		this.calculatingRoute = false;
		this.routeBtnEnabled = false;
		this.routeColorsCount = 1;

		// Default validation state
		this.validation = {
			isPristine: true,
			isValid: false
		};

		this.zoomToRouteSubscription = this.eventAggregator.subscribe(AdminRouteEventItemType.ZOOM_TO_ROUTE, () => {
			if (!this.routeLayerInMap) {
				return;
			}

			this.zoomToRoute();
		});

		this.toggleShowRouteSubscription = this.eventAggregator.subscribe(AdminRouteEventItemType.TOGGLE_SHOW_ROUTE, (showRoute) => {
			if (!this.routeLayerInMap) {
				return;
			}

			if (showRoute) {
				this.routeLayerInMap.addTo(this.map);
			}
			else {
				this.routeLayerInMap.removeFrom(this.map);
			}
		});

		this.mapLocationSelectedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_LOCATION_SELECTED, (locationEvent) => {
			if (locationEvent.locationType === 'START') {
				this.startLocationSelected(locationEvent);
			}
			else if (locationEvent.locationType === 'VIA') {
				this.viaLocationSelected(locationEvent, null);
			}
			else if (locationEvent.locationType === 'END') {
				this.endLocationSelected(locationEvent);
			}
		});

		this.routeInformations = [];

		this.routeInformation = {
			ViaLocations: []
		};
		this.routeInformation.ViaLocations.push(this.getEmptyMapRoutePoint());

		this.routingService.getResistanceSettings().then((x) => {
			this.routeInformation.ResistanceSetting = x[0];
		});

		this.routingService.getCoordinateSystems().then((x) => {
			this.routeInformation.CoordinateSystem = x[0];
		});

		this.routingService.getVehicleDefinitions().then((x) => {
			this.vehicleDefinitions = x;
			this.vehicleDefinition = x[0];
		});

		this.setLocationsToDefault();

		if (this.route && this.route.NetworkReferences && this.route.Geometry) {
			if (this.isFacitRutt) {
				const facitRoute = this.route as IFacitRoute;
				if (facitRoute.PickupLocationNumber) {
					const searchResult = await this.search(facitRoute.PickupLocationNumber);
					if (searchResult.length > 0) {
						this.endLocationSelected(searchResult[0]);
						this.validation.isPristine = true;
					}
				}
			}
			this.originalNetworkReferences = {
				Action: NetworkReferenceAction.Add,
				NetworkReferences: this.route.NetworkReferences
			};

			this.validation.isValid = true;

			this.showRouteInMap(false);
			this.zoomToRoute();
		}

		this.applicationRepo.canAddLocations = this.canAddLocations;
	}

	detached() {
		this.originalNetworkReferences = undefined;

		if (this.zoomToRouteSubscription) {
			this.zoomToRouteSubscription.dispose();
		}

		if (this.toggleShowRouteSubscription) {
			this.toggleShowRouteSubscription.dispose();
		}

		if (this.mapLocationSelectedSubscription) {
			this.mapLocationSelectedSubscription.dispose();
		}

		this.routeInformation = undefined;
		this.routeInformations = undefined;

		if (this.routeLayerInMap) {
			this.routeLayerInMap.removeFrom(this.map);
			this.routeLayerInMap = null;
		}

		if (this.startMarker) {
			this.startMarker.removeFrom(this.map);
			this.startMarker = null;
		}

		if (this.viaMarkers) {
			this.viaMarkers.forEach(marker => {
				marker.removeFrom(this.map);
			});

			this.viaMarkers = null;
		}

		if (this.endMarker) {
			this.endMarker.removeFrom(this.map);
			this.endMarker = null;
		}

		this.startLocation = null;
		this.endLocation = null;
		this.viaLocations = null;
	}

	toggle() {
		this.expanded = !this.expanded;
	}

	/**
	 * replaces 'canAddViaPoint' in appRepo with this implementation
	 */
	canAddViaPoints = (): boolean => {
		if (!this.viaLocations) {
			return true;
		}

		if (this.viaLocations[this.viaLocations.length - 1] &&
			!this.viaLocations[this.viaLocations.length - 1].name) {
			return false;
		}

		return this.viaLocations.length < this.applicationRepo.maxNumViaLocations;
	}

	/**
	 * replaces 'canAddLocations' in appRepo with this implementation
	 */
	canAddLocations = (): boolean => {
		return this.route && !this.route.IsCheckedIn;
	}

	changeVehicleDefinition() {
		this.onFormChanged();
	}

	/**
	 * Calculates a route
	 */
	calculateRoute = async (action?: "Add" | "Remove") => {
		this.calculatingRoute = true;

		try {
			const index = this.viaLocations.findIndex(x => (x == null || x.name == null));

			if (index != -1) {
				this.viaLocations.splice(index, 1);
			}

			const viaLocations: IRoutingLocation[] = this.viaLocations.map(x => {
				return {
					Id: x.id,
					Type: x.type,
					X: x.easting,
					Y: x.northing
				};
			});

			const startLocation: IRoutingLocation = {
				Id: this.startLocation.id,
				Type: this.startLocation.type,
				X: this.startLocation.easting,
				Y: this.startLocation.northing
			};

			const endLocation: IRoutingLocation = {
				Id: this.endLocation.id,
				Type: this.endLocation.type,
				X: this.endLocation.easting,
				Y: this.endLocation.northing
			};

			const routeRequest: ICalculatedRouteRequest = {
				From: startLocation,
				To: endLocation,
				Via: viaLocations,
				ResistanceSettingId: this.routeInformation.ResistanceSetting.Id,
				EpsgCode: this.routeInformation.CoordinateSystem.EPSG,
				VehicleDefinitionId: this.vehicleDefinition.Id				
			};

			if (this.router && this.router.id === 'TEST') {
				routeRequest.UseTestRouter = true;
			}

			const calculatedRoute: ICalculatedRoute = await this.routingService.calculateRoute(routeRequest);

			if (calculatedRoute.ErrorResponse) {
				this.eventAggregator.publish('alertError', calculatedRoute.ErrorResponse);
				this.calculatingRoute = false;
				this.validation.isValid = false;
			}
			else {
				this.routeBtnEnabled = false;

				if (this.isFacitRutt) {
					this.setCalculatedRouteForFacitRutt(calculatedRoute);
				}
				else {
					const viaLocationsJson = JSON.stringify(this.viaLocations);
					const startLocationsJson = JSON.stringify(this.startLocation);
					const endLocationsJson = JSON.stringify(this.endLocation);

					const routeInfo: IRouteInformationWithMerge = {
						Id: new Date().getTime(),
						Color: this.getNextColor(),
						ResistanceSetting: this.routeInformation.ResistanceSetting,
						VehicleDefinition: this.vehicleDefinition,
						CalculatedRoute: calculatedRoute,
						CoordinateSystem: this.routeInformation.CoordinateSystem,
						StartLocation: JSON.parse(startLocationsJson),
						ViaLocations: JSON.parse(viaLocationsJson),
						EndLocation: JSON.parse(endLocationsJson),
						MergeAction: action
					};

					this.routeInformations.push(routeInfo);

					// Merge rotes
					await this.mergeNetworks();
				}
				this.validation.isValid = true;
				this.calculatingRoute = false;
			}
		}
		catch (error) {
			this.validation.isValid = false;
			this.calculatingRoute = false;
		}
	}

	setLocationsToDefault(): void {
		this.startLocation = this.getEmptyMapRoutePoint();
		this.endLocation = this.getEmptyMapRoutePoint();
		this.viaLocations = [];
		this.viaLocations.push(this.getEmptyMapRoutePoint());
		this.removeStartMarker();
		this.removeEndMarker();
		this.removeViaMarkers();
	}

	/**
	 * Makes sure there is an 'empty' slot for a via location in the array
	 */
	addViaLocation(): void {
		if (!this.viaLocations) {
			this.viaLocations = [];
		}

		if (this.viaLocations.length > 0 &&
			this.viaLocations[this.viaLocations.length - 1] == null) {
			// Last of via locations is null so not adding any more vias
			return;
		}

		if (this.viaLocations[this.viaLocations.length - 1] &&
			!this.viaLocations[this.viaLocations.length - 1].name) {
			// Last of via locations is "incomplete" so not adding any more vias
			return;
		}

		this.viaLocations.push(this.getEmptyMapRoutePoint());
		this.onFormChanged();
	}

	/**
	 * swaps start location/end location and all via locations
	 */
	changeRouteDirection() {
		let tempStart: IMapRoutePoint = this.getEmptyMapRoutePoint();
		let tempEnd: IMapRoutePoint = this.getEmptyMapRoutePoint();

		if (this.startLocation.name) {
			tempEnd = this.startLocation;
		}
		else {
			tempEnd = null;
		}

		if (this.endLocation.name) {
			tempStart = this.endLocation;
		}
		else {
			tempStart = null;
		}

		this.startLocation = tempStart;
		this.endLocation = tempEnd;
		this.viaLocations.reverse();
		this.showRoutePointsInMap(this.viaLocations, this.startLocation, this.endLocation);
		this.onFormChanged();
	}

	/**
	 * Called from autocomplete
	 */
	search = (s: string) => {
		return this.placeSearchService.getSuggestions(s, [SearchType.SITE, SearchType.COORDINATE]);
	}

	/**
	 * Called from autocomplete
	 */
	filterLastSearches = (items: IPlaceSearchItem[]): IPlaceSearchItem[] => {
		return items.filter(x => x.type === SearchType.SITE || x.type === SearchType.COORDINATE);
	}

	/**
	 * Called from autocomplete
	 */
	filterFavorites = (items: IPlaceSearchItem[]): IPlaceSearchItem[] => {
		return items.filter(x => x.type === SearchType.SITE || x.type === SearchType.COORDINATE);
	}

	/**
	 * Called from autocomplete
	 */
	formatItem = (searchItem: IPlaceSearchItem) => {
		return this.placeSearchService.formatItem(searchItem);
	}

	/**
	 * Listens to when any location has been "unselected" from autocomplete inputs
	 * @param event 
	 */
	locationUnselected(event: any) {
		let shouldRedraw = !this.startLocation || !this.endLocation;

		if (!shouldRedraw) {
			for (let i = 0; i < this.viaLocations.length; i++) {
				if (this.viaLocations[i]) {
					continue;
				}

				shouldRedraw = true;
				break;
			}
		}

		if (shouldRedraw) {
			this.onFormChanged();
		}
	}

	/**
	 * From autocomplete or draggable
	 * @param event 
	 * @param index 
	 */
	viaLocationSelected(event: any, index: number) {
		let searchItem: IMapRoutePoint;
		if (event.detail) {
			searchItem = event.detail as IMapRoutePoint;
		}
		else {
			searchItem = event as IMapRoutePoint;
		}

		if (searchItem.name != null) {
			const lastViaLocationIsEmpty = () => {
				if (!this.viaLocations || this.viaLocations.length === 0) {
					return false;
				}

				if (!this.viaLocations[this.viaLocations.length - 1] ||
					!this.viaLocations[this.viaLocations.length - 1].name) {
					return true;
				}

				return false;
			};

			if (index == null) {
				if (lastViaLocationIsEmpty()) {
					this.viaLocations.splice(this.viaLocations.length - 1, 1, searchItem);
				}
				else {
					this.viaLocations.push(searchItem);
				}
			}
			else {
				this.viaLocations.splice(index, 1, searchItem);
			}

			this.showRoutePointsInMap(this.viaLocations, this.startLocation, this.endLocation);
		}

		this.onFormChanged();
	}

	/**
	 * Removes via position for given index
	 * @param index 
	 */
	removeVia(index) {
		if (index == null) {
			this.viaLocations = [];
		}
		else {
			this.viaLocations.splice(index, 1);
		}

		this.removeViaMarkers();
		this.showRoutePointsInMap(this.viaLocations, null, null);
		this.onFormChanged();
	}

	/**
	 * From autocomplete or draggable
	 * @param event 
	 */
	startLocationSelected(event: any) {
		let searchItem: IMapRoutePoint;
		if (event.detail) {
			searchItem = event.detail as IMapRoutePoint;
		}
		else {
			searchItem = event as IMapRoutePoint;
		}

		if (searchItem.name != null) {
			this.startLocation = searchItem;
			this.showRoutePointsInMap(this.viaLocations, this.startLocation, this.endLocation);
			this.addMottagningsplats(searchItem);
		}

		this.onFormChanged();
	}

	/**
	 * Removes start position
	 */
	removeStart() {
		this.startLocation = null;
		this.removeStartMarker();
		this.startLocation = this.getEmptyMapRoutePoint();
		this.onFormChanged();
	}

	/**
	 * From autocomplete or draggable
	 * @param event 
	 */
	endLocationSelected(event: any) {

		let searchItem: IMapRoutePoint;
		if (event.detail) {
			searchItem = event.detail as IMapRoutePoint;
		}
		else {
			searchItem = event as IMapRoutePoint;
		}

		if (this.isFacitRutt && searchItem.type !== SearchType.SITE) {
			// We are not interested of other search items than site
			return;
		}

		if (searchItem.name != null) {
			this.endLocation = searchItem;
			this.showRoutePointsInMap(this.viaLocations, this.startLocation, this.endLocation);
			this.addMottagningsplats(searchItem);
		}

		this.onFormChanged();
	}

	addMottagningsplats(searchItem){
		this.eventAggregator.publish("searchItemField", {searchItem: searchItem});
	}

	/**
	 * Removes end position
	 */
	removeEnd() {
		this.endLocation = null;
		this.endLocation = this.getEmptyMapRoutePoint();
		this.removeEndMarker()
		this.onFormChanged();
	}

	/**
	 * Removes a route info from array (will trigger reload of conponents)
	 * @param routeInfo The route info to remove ('the caller')
	 */
	removeRouteInformation(routeInfo: IRouteInformationWithMerge): void {

		const index = this.routeInformations.findIndex(x => x.Id == routeInfo.Id);

		// If last route -> decrement route color index by one
		if (index === this.routeInformations.length - 1) {
			if (this.routeColorsCount === 1) {
				this.routeColorsCount = 4;
			}
			else {
				this.routeColorsCount--;
			}
		}

		this.routeInformations.splice(index, 1);
		this.mergeNetworks();
		this.onFormChanged();

		if (this.routeInformations.length === 0) {
			this.validation.isValid = false;
		}
	}

	/**
	 * When any of start, stop or via has changed this function will redraw the layers
	 * @param viaPoints 
	 * @param startPoint 
	 * @param endPoint 
	 */
	private showRoutePointsInMap(viaPoints: IPlaceSearchItem[], startPoint: IPlaceSearchItem, endPoint: IPlaceSearchItem) {
		const setRouteViaLocation = (latlng: L.LatLng, index: number) => {
			const location = this.createNewLocation(latlng);
			const viaLocation = {
				id: "",
				name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
				type: SearchType.COORDINATE,
				latlng: location.latlng,
				projected: location.projected,
				easting: location.projected.x,
				northing: location.projected.y
			};

			this.viaLocations.splice(index, 1, viaLocation);
			this.onFormChanged();
		}

		const setRouteEndLocation = (latlng: L.LatLng) => {
			const location = this.createNewLocation(latlng);
			this.endLocation = {
				id: "",
				name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
				type: SearchType.COORDINATE,
				latlng: location.latlng,
				projected: location.projected,
				easting: location.projected.x,
				northing: location.projected.y
			};

			this.onFormChanged();
		}

		const setRouteStartLocation = (latlng: L.LatLng) => {
			const location = this.createNewLocation(latlng);
			this.startLocation = {
				id: "",
				name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
				type: SearchType.COORDINATE,
				latlng: location.latlng,
				projected: location.projected,
				easting: location.projected.x,
				northing: location.projected.y
			};

			this.onFormChanged();
		}

		if (viaPoints != null) {
			this.removeViaMarkers();
			const icons = this.iconService.getIconViaMap();
			for (let i = 0; i < viaPoints.length; i++) {
				if (viaPoints[i] != null && viaPoints[i].name != null) {
					const latlng = this.mapCrs.unproject({ x: viaPoints[i].easting, y: viaPoints[i].northing });
					const viaIcon = L.icon({
						iconUrl: icons.get(i),
						iconSize: [26, 38],
						iconAnchor: [13, 38]
					});

					const viaMarker = L.marker(latlng, { icon: viaIcon, draggable: true, zIndexOffset: 1000 }).addTo(this.map);
					viaMarker.on('dragend', (e) => {
						const latlng = new L.LatLng(e.target._latlng.lat, e.target._latlng.lng);
						viaMarker.setLatLng(latlng);
						setRouteViaLocation(latlng, i);
					});

					this.viaMarkers.push(viaMarker);
				}
			}
		}

		if (startPoint != null && startPoint.name != null) {
			this.removeStartMarker(); 
			const startLatlng = this.mapCrs.unproject({ x: startPoint.easting, y: startPoint.northing });
			const startIcon = L.icon({
				iconUrl: this.iconService.getIcon(IconType.IconMapStart),
				iconSize: [26, 38],
				iconAnchor: [13, 38]
			});

			const startMarker = L.marker(startLatlng, { icon: startIcon, draggable: true, zIndexOffset: 1000 }).addTo(this.map);
			startMarker.on('dragend', (e) => {
				const latlng = new L.LatLng(e.target._latlng.lat, e.target._latlng.lng);
				startMarker.setLatLng(latlng);
				setRouteStartLocation(latlng);
			});

			this.startMarker = startMarker;
		}
		
		if (endPoint != null && endPoint.name != null) {
			this.removeEndMarker();
			const endlatlng = this.mapCrs.unproject({ x: endPoint.easting, y: endPoint.northing });
			const stopIcon = L.icon({
				iconUrl: this.iconService.getIcon(IconType.IconMapStopp),
				iconSize: [26, 38],
				iconAnchor: [13, 38]
			});

			const endMarker = L.marker(endlatlng, { icon: stopIcon, draggable: true, zIndexOffset: 1000 }).addTo(this.map);
			endMarker.on('dragend', (e) => {
				const latlng = new L.LatLng(e.target._latlng.lat, e.target._latlng.lng);
				endMarker.setLatLng(latlng);
				setRouteEndLocation(latlng);
			});

			this.endMarker = endMarker;
		}
	}

	/**
	 * Checks if form is valid, if form is pristine and if routeBtn should be enabled
	 */
	private onFormChanged(): void {
		// Whenever we are here the form can no longer be prestine can it?
		this.validation.isPristine = false;

		// If start and end we should be allowed to route
		if (this.startLocation && this.startLocation.name && this.endLocation && this.endLocation.name) {
			this.routeBtnEnabled = true;
		}
		else {
			this.routeBtnEnabled = false;
			this.validation.isValid = false;
			return;
		}

		if (this.isFacitRutt) {
			this.validation.isValid = false;
			if (this.endLocation.type !== SearchType.SITE) {
				this.routeBtnEnabled = false;
				return;
			}

			return;
		}

		return;
	}

	/**
	 * Removes start marker from map
	 */
	private removeStartMarker(): void {
		if (this.startMarker) {
			this.startMarker.remove();
			this.startMarker = null;
		}
	}

	/**
	 * Removes end marker from map
	 */
	private removeEndMarker(): void {
		if (this.endMarker) {
			this.endMarker.remove();
			this.endMarker = null;
		}
	}

	/**
	 * Removes via markers from map
	 */
	private removeViaMarkers(): void {
		if (this.viaMarkers) {
			this.viaMarkers.forEach(marker => {
				marker.remove();
			});
			this.viaMarkers = [];
		}
		else {
			this.viaMarkers = [];
		}
	}

	/**
	 * Calculated route for Facitrutt
	 * @param calculatedRoute 
	 */
	private setCalculatedRouteForFacitRutt(calculatedRoute: ICalculatedRoute): void {
		if (!this.isFacitRutt) {
			throw new Error(`This function should not be called when routeType is ${this.route.RouteType}`);
		}

		const viaLocationsJson = JSON.stringify(this.viaLocations);
		const startLocationsJson = JSON.stringify(this.startLocation);
		const endLocationsJson = JSON.stringify(this.endLocation);

		this.routeInformation.ViaLocations = JSON.parse(viaLocationsJson);
		this.routeInformation.StartLocation = JSON.parse(startLocationsJson);
		this.routeInformation.EndLocation = JSON.parse(endLocationsJson);
		this.routeInformation.CalculatedRoute = calculatedRoute;
		this.routeInformation.VehicleDefinition = this.vehicleDefinition;

		if (this.endLocation.type == SearchType.SITE) {
			(this.route as IFacitRoute).PickupLocationName = this.endLocation.name;
			(this.route as IFacitRoute).PickupLocationNumber = this.endLocation.id;
		}

		// Important to set new NetworkReferences from calculated route
		this.route.NetworkReferences = calculatedRoute.NvdbLinkInfo;
		this.route.Geometry = [calculatedRoute.Geometry];
		this.route.Distance = calculatedRoute.CalculatedLength;
		this.route.Resistance = calculatedRoute.CalculatedCost;

		this.showRouteInMap(true);
	}

	/**
	 * Show route geometry in map
	 * @param geometry 
	 * @param isRouteCalculationResult Whether the geometry is from a route result or not
	 */
	private showRouteInMap(isRouteCalculationResult: boolean): void {
		if (this.routeLayerInMap) {
			this.routeLayerInMap.removeFrom(this.map);
		}

		if (!this.route || !this.route.Geometry) {
			return;
		}

		const geometry = this.route.Geometry;

		const geom: number[][][] = [];
		for (let index = 0; index < geometry.length; index++) {
			const mappedGeom = geometry[index].map(x => [x.X, x.Y]);
			geom.push(mappedGeom);
		}

		this.routeLayerInMap = new L.Proj.GeoJSON;
		const geojson: Proj4GeoJSONFeature = {
			type: 'Feature',
			geometry: {
				type: 'MultiLineString',
				coordinates: geom
			},
			properties: {
				
			},
			crs: {
				type: 'name',
				properties: {
					name: 'urn:ogc:def:crs:EPSG::3006'
				}
			}
		};

		const style: L.PathOptions = {
			color: '#1F6575',
			weight: 6
		};

		if (this.isFacitRutt && isRouteCalculationResult) {
			style.dashArray = '10, 15';
		}

		this.routeLayerInMap = L.Proj.geoJson(geojson, {
			style: (feature: any) => {
				return style;
			}
		});

		this.routeLayerInMap.addTo(this.map);
	}

	/**
	 * Zoom to the extent of the route
	 */
	private zoomToRoute() {
		if (this.routeLayerInMap) {
			this.map.fitBounds(this.routeLayerInMap.getBounds().pad(Math.sqrt(2) / 2));
		}
	}

	/**
	 * Create a new location for a given latlng
	 * @param latlng 
	 */
	private createNewLocation(latlng: L.LatLng): IMapRoutePoint {
		const newPoint = this.mapConfig.getMapCrs().crs.project(latlng);
		const mapMarker = {
			id: "",
			name: Math.round(newPoint.x) + ', ' + Math.round(newPoint.y),
			type: SearchType.COORDINATE,
			latlng: latlng,
			projected: newPoint,
			easting: newPoint.x,
			northing: newPoint.y
		};

		return mapMarker;
	}

	private getEmptyMapRoutePoint(): IMapRoutePoint {
		return {
			id: null,
			type: null,
			name: null,
			latlng: null,
			projected: null
		};
	}

	/**
	 * Merge original network reference (if any) with changes (Adds/Removes)
	 */
	private async mergeNetworks(): Promise<void> {
		const refsToMerge: INetworkReferencesWithAction[] = [];
		if (this.originalNetworkReferences) {
			refsToMerge.push(this.originalNetworkReferences);
		}

		this.routeInformations.forEach(x => {
			const action: NetworkReferenceAction = x.MergeAction === 'Add' ? NetworkReferenceAction.Add : NetworkReferenceAction.Remove;
			refsToMerge.push({
				NetworkReferences: x.CalculatedRoute.NvdbLinkInfo,
				Action: action
			});

		});

		const directedExtents = this.route.RouteType === RouteType.TpLed ? true : false;

		const mergedNetworkReferences = await this.adminRouteService.merge(refsToMerge, directedExtents);
		this.route.NetworkReferences = mergedNetworkReferences.NetworkReferences;
	}

	/**
	 * Get the next available route color
	 */
	private getNextColor(): string {
		// Alwas skip 0 (blue)
		// Reset the color count when array is empty
		if (this.routeInformations.length === 0) {
			this.routeColorsCount = 1;
		}

		const ret = this.applicationRepo.routeColors[this.routeColorsCount];

		if (this.routeColorsCount === 4) {
			this.routeColorsCount = 1;
		}
		else {
			this.routeColorsCount++;
		}

		return ret;
	}
}

export class RouteDistanceValueConverter {
	toView(value?: number) {
		if (!value) {
			return '';
		}

		const length = value;

		return length.toFixed(1);
	}
}

export class CalculatedDistanceValueConverter {
	toView(value?: number) {
		if (!value) {
			return '';
		}

		const length = value;

		const km = length / 1000;
		return km.toFixed(1);
	}
}