// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-item {
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
    color: #444444;
}

.history-header {
    font-size: 12px;
    font-weight: normal;
    padding-left: 5px;
}

.history-date-header {
    font-size: 12px;
    font-weight: normal;
}

.history-date {
    margin-left: 0px;
    margin-bottom: 5px;
    background: #BBBBBB;
}

.hist-row {
    padding-left: 5px;
}

.hist-header-row {
    padding-left: 5px;
}

.hist-row:nth-child(even) {
  background-color: #E0E0E0;
}

.hist-row:nth-child(odd) {
  background-color: #EEEEEE;
}

.icon-icon-eye.history:before {
    color: #444444;
    font-weight: normal !important;
    font-size: 16px;
}

.history-action-btn{
    font-size: 16px;
    padding: 0px 0px 0px 0px;
    margin-left: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/routing/route-history/route-history.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;IACI,cAAc;IACd,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,wBAAwB;IACxB,2BAA2B;AAC/B","sourcesContent":[".history-item {\r\n    font-size: 12px;\r\n    font-weight: bold;\r\n    padding-left: 5px;\r\n    color: #444444;\r\n}\r\n\r\n.history-header {\r\n    font-size: 12px;\r\n    font-weight: normal;\r\n    padding-left: 5px;\r\n}\r\n\r\n.history-date-header {\r\n    font-size: 12px;\r\n    font-weight: normal;\r\n}\r\n\r\n.history-date {\r\n    margin-left: 0px;\r\n    margin-bottom: 5px;\r\n    background: #BBBBBB;\r\n}\r\n\r\n.hist-row {\r\n    padding-left: 5px;\r\n}\r\n\r\n.hist-header-row {\r\n    padding-left: 5px;\r\n}\r\n\r\n.hist-row:nth-child(even) {\r\n  background-color: #E0E0E0;\r\n}\r\n\r\n.hist-row:nth-child(odd) {\r\n  background-color: #EEEEEE;\r\n}\r\n\r\n.icon-icon-eye.history:before {\r\n    color: #444444;\r\n    font-weight: normal !important;\r\n    font-size: 16px;\r\n}\r\n\r\n.history-action-btn{\r\n    font-size: 16px;\r\n    padding: 0px 0px 0px 0px;\r\n    margin-left: 0px !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
