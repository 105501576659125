import { RouteStateMap, RouteState } from "components/admin/admin-constants/index";

export class RouteStateFormatValueConverter {
    toView(value: number) {
        if (RouteStateMap.RouteStateName.has(value)) {
            return RouteStateMap.RouteStateName.get(value);
		}
		
		return RouteStateMap.RouteStateName.get(RouteState.Aktiv);
    }
}
