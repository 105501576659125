import { autoinject, bindable } from 'aurelia-framework';
import * as L from 'leaflet';
import { GeoJsonTypes } from 'geojson';
import { Route } from '../route';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { IRouteInformation } from 'services/application-repository/models/route-information.interface';
import { IconType } from 'services/assets/enums/icon-type.enum';
import { IconService } from 'services/assets/services/icon.service';

@autoinject()
export class RouteCard {
	@bindable route: IRouteInformationWithMerge;
	@bindable parent: Route;

	private map: L.Map;
	private mapCrs;
	protected showRoute = true;
	protected showDirection = false;

	private geojson: any;
	private arrowLayer: L.LayerGroup;
	private routeLayerInMap: L.Proj.GeoJSON;
	private markers: L.Marker[] = [];

	get routeColor(): string {
		return 'background-color: ' + this.route.Color + '!important';
	}

	get routeLength(): string {
		const length = this.route.CalculatedRoute.CalculatedLength;

		const prefix = this.route.MergeAction === 'Remove' ? '-' : '';

		if (length < 1000) {
			return `${prefix}${length}  m`;
		}

		const km = length / 1000;
		return `${prefix}${km.toFixed(1)} km`;
	}

	constructor(
		private applicationRepo: ApplicationRepository,
		private iconService: IconService) {
		
	}

	attached() {
		this.map = this.applicationRepo.map;
		this.mapCrs = this.applicationRepo.mapCrs;

		this.showRouteInMap();
		this.showRoutePointsInMap();
	}

	detached() {
		if (this.map) {
			if (this.routeLayerInMap) {
				this.routeLayerInMap.removeFrom(this.map);
			}

			if (this.markers) {
				this.markers.forEach((x) => {
					x.removeFrom(this.map);
				});
			}
		}

		this.routeLayerInMap = null;
		this.markers = null;
		this.route = null;
	}

	/**
	 * Removes this instance from applicationRepo.routesInformation
	 * @param $event 
	 */
	removeRoute($event) {
		$event.stopPropagation();
		this.parent.removeRouteInformation(this.route);
		this.arrowLayer.remove();
	}

	/**
	 * Show or hide the route and markers
	 * @param $event The click event
	 */
	toggleRoute($event){
		$event.stopPropagation();

		this.showRoute = this.showRoute ? false : true;
		
		this.clearMap();

		if (this.showRoute) {
			this.showRouteInMap();
			this.showRoutePointsInMap();
			this.showDirection = false;
		}
	}

	/**
	 * Zoom to the extent of the route
	 * @param $event The click event
	 */
	zoomToRoute($event): void {
		$event.stopPropagation();

		const geometry = [];
		this.route.CalculatedRoute.Geometry.forEach(point => {
			const geom = {y: point.Y, x: point.X};
			geometry.push(this.mapCrs.unproject(geom as any));
		});

		const bounds = new L.LatLngBounds(geometry);
		this.map.fitBounds(bounds);
	}

	/**
	 * Show direction of the route
	 * @param $event The click event
	 */
	private toggleShowDirection(event): void {
		event.stopPropagation();
		this.showDirection = this.showDirection ? false : true;

		if(!this.arrowLayer){
			this.arrowLayer = new L.LayerGroup;
		}

		if (this.showDirection) {
			const t: L.LatLng[] = [];
			this.geojson.geometry.coordinates.forEach(element => {
				const startLatlng = (this.applicationRepo.mapCrs as any).unproject({ x: element[0], y: element[1] });
				t.push(startLatlng);
			});

			const polyline = L.polyline(t, {
				color: this.geojson.properties.color,
				weight: 6
			}).addTo(this.arrowLayer);
			
			const arrowHead = L.polylineDecorator(polyline, {
				patterns: [{
					offset: 10,
					repeat: 60,
					symbol: L.Symbol.arrowHead({
						pixelSize: 10,
						pathOptions: { fillOpacity: 1, weight: 5, color: this.geojson.properties.color }
					})
				}]
			}).addTo(this.arrowLayer);
			this.arrowLayer.addTo(this.routeLayerInMap);
		}

		else {
			this.routeLayerInMap.removeLayer(this.arrowLayer);
		}
	}

	/**
	 * Removes layers from map
	 */
	private clearMap(): void {
		if (!this.map) {
			return;
		}

		if (this.markers) {
			this.markers.forEach(marker => {
				marker.removeFrom(this.map);
			});

			this.markers = [];
		}
		
		if (this.routeLayerInMap) {
			this.routeLayerInMap.removeFrom(this.map);
			this.routeLayerInMap = null;
		}
	}

	/**
	 * Shows the route in the map
	 */
	private showRouteInMap(): void {
		if (!this.route.CalculatedRoute) {
			return;
		}

		this.routeLayerInMap = new L.Proj.GeoJSON;
		this.geojson = {
			type: 'Feature' as GeoJsonTypes,
			geometry: {
				type: 'LineString',
				coordinates: this.route.CalculatedRoute.Geometry.map(
					x => { return [x.X, x.Y]; })
			},
			crs: {
				type: 'name',
				properties: {
					name: 'urn:ogc:def:crs:EPSG::3006'
				}
			},
			properties: {
				color: this.route.Color,
				weight: 6
			}
		};

		this.routeLayerInMap = L.Proj.geoJson(this.geojson, {
			style: (feature: any) => {
				return {
					color: feature.properties.color,
					weight: feature.properties.weight,
					dashArray: "10, 15"
				};
			}
		});

		this.routeLayerInMap.addTo(this.map);
		this.routeLayerInMap.bringToFront();
	}

	/**
	 * Adds markers to the map
	 */
	private showRoutePointsInMap(): void {
		if (!this.route.CalculatedRoute) {
			return;
		}

		const startLatlng = this.mapCrs.unproject({ x: this.route.StartLocation.easting, y: this.route.StartLocation.northing });
		const startIcon = L.icon({
			iconUrl: this.iconService.getIcon(IconType.IconMapStart),
			iconSize: [26, 38],
			iconAnchor: [13, 38]
		});

		const startMarker = L.marker(startLatlng, { icon: startIcon }).addTo(this.map);
		this.markers.push(startMarker);

		if (this.route.ViaLocations && this.route.ViaLocations.length > 0) {
			const iconViaMap = this.iconService.getIconViaMap();
			this.route.ViaLocations.forEach((x, i) => {
				const latlng = this.mapCrs.unproject({ x: x.easting, y: x.northing });

				const viaIcon = L.icon({
					iconUrl: iconViaMap.get(i),
					iconSize: [26, 38],
					iconAnchor: [13, 38]
				});

				const viaMarker = L.marker(latlng, { icon: viaIcon }).addTo(this.map);
				this.markers.push(viaMarker);
			});
		}

		const endlatlng = this.mapCrs.unproject({ x: this.route.EndLocation.easting, y: this.route.EndLocation.northing });
		const stopIcon = L.icon({
			iconUrl: this.iconService.getIcon(IconType.IconMapStopp),
			iconSize: [26, 38],
			iconAnchor: [13, 38]
		});

		const endMarker = L.marker(endlatlng, { icon: stopIcon }).addTo(this.map);
		this.markers.push(endMarker);
	}
}

export interface IRouteInformationWithMerge extends IRouteInformation {
	MergeAction: "Add" | "Remove";
}