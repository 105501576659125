import { autoinject, computedFrom, Disposable, LogManager } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { Logger } from 'aurelia-logging';
import { DialogService } from "aurelia-dialog";
import moment from "moment";
import { Alert, AlertModel } from 'components/common/dialog/alert';
import { UnvalidFeatures } from "../nav-bar-tabs/unvalid-features/unvalid-features";
import { DynamicBlocksService, IDynamicBlock } from "../services/dynamic-block-service";
import { DynamicBlockTypes } from "../admin-constants/dynamicblock-types";
import { RouteType } from "../admin-constants/route-types";
import { AppAuthService } from "services/authentication/app-auth-service";
import { AppRole } from "services/authentication/models/app-roles.enum";
import { IJobInfoData, JobStatus, ProgressCallback, TneAsyncResolver } from "../services/tne-async-resolver";
import { ApplicationRepository } from "services/application-repository/application-repository";
import { ToDosService } from "../services/to-dos/to-dos-service";
import { RouteState } from "../admin-constants/route-states";
import { wait } from "components/admin/services/utilities/wait";
import { AdminRouteService } from "../services/admin-route/admin-route-service";
import { IPrincipal } from "services/authentication/models/principal.interface";
import { LayerEventType } from "vv-constants/layer-event-type.enum";
import { IRoute } from "../services/admin-route/models/route-interface";
import { ToDoStatus } from "../services/to-dos/models/to-do-status.model";
import { ICheckOutRequest } from "../services/admin-route/models/checkout-request.interface";
import { AdminRouteEventItemType } from "../admin-constants/event-types/admin-route-event-type.enum";
import { AdminToDoEventItemType } from "../admin-constants/event-types/admin-todo-event-item-types.enum";
import { AdminDynamicBlockEventItemType } from "../admin-constants/event-types/admin-dynamic-block-event-item-type.enum";
import { AdminCheckedOutEventItemType } from "../admin-constants/event-types/admin-checked-out-event-item-type.enum";
import { AdminHistoryEventItemType } from "../admin-constants/event-types/admin-history-event-item-type.enum";

@autoinject()
export class Routing {
	routeType: any; // Binds to vv-select routeType -> dummy object
	dynamicblockType: any; // Binds to vv-select dynamicblocksType -> dummy object
	route: IRoute;
	dynamicBlock: IDynamicBlock;
	toolPanelVisible = false;

	showRoute = true;
	adminNavPanel: any;
	adminToolPanel: any;

	loading: boolean;
	jobProgress: any;

	dynamicblockTypes = [
		{
			id: DynamicBlockTypes.Coord,
			name: 'Coord'
		}, 
		{
			id: DynamicBlockTypes.Polygon,
			name: 'Polygon'
		}
	]

	routeTypes = [
		{
			id: RouteType.FacitRutt,
			name: 'Facitrutt'
		}, {
			id: RouteType.RekommenderadLed,
			name: 'Rekommenderad led'
		}, {
			id: RouteType.TimmerLed,
			name: 'Timmerled'
		}, {
			id: RouteType.TpLed,
			name: 'TP-led'
		}
	];

	routeSettingsValidation: {
		isPristine: boolean,
		isValid: boolean
	};

	routeValidation: {
		isPristine: boolean,
		isValid: boolean
	};
	routeSiteIsPrestine: boolean;

	private setFeatureLoadStartSubscription: Disposable;
	private setFeatureSubscription: Disposable;
	private historySelectedSubscription: Disposable;
	
	private lastProgressText: string = undefined; // Used by jobProgressCallback to hold last state
	private logger: Logger;
	private principal: IPrincipal;

	@computedFrom('routeSettingsValidation.isPristine', 'routeValidation.isPristine' , 'routeSiteIsPrestine')
	get isPristine(): boolean {
		if (this.isTpLed) {
			return this.routeSettingsValidation.isPristine && this.routeValidation.isPristine && this.routeSiteIsPrestine;
		}
		return this.routeSettingsValidation.isPristine && this.routeValidation.isPristine;
	}

	@computedFrom('routeSettingsValidation.isValid', 'routeValidation.isValid')
	get isValid(): boolean {
		return this.routeSettingsValidation.isValid && this.routeValidation.isValid;
	}

	@computedFrom('route.RouteType')
	get isTpLed(): boolean {
		if (!this.route) {
			return false;
		}

		return this.route.RouteType === RouteType.TpLed;
	}

	@computedFrom('principal')
	get isCommitter(): boolean {
		if (!this.principal) {
			return false;
		}

		return this.principal.isInRole(AppRole.VV_COMMITTER)
	}

	@computedFrom('principal')
	get isEditor(): boolean {
		if (!this.principal) {
			return false;
		}

		return this.principal.isInRole(AppRole.VV_EDITOR)
	}

	@computedFrom('isCommitter')
	get deleteEnabled(): boolean {
		// User must be a 'Committer'
		if (!this.isCommitter) {
			return false;
		}

		// Route must be pristine
		return this.isPristine;
	}

	@computedFrom('isCommiter')
	get endRouteEnabled():boolean{
		if (!this.isCommitter) {
			return false;
		}
		else{
			return true;
		}
	}

	@computedFrom('route.IsCheckedIn', 'isPristine', 'isValid')
	get saveEnabled(): boolean {
		if (!this.route) {
			return false;
		}

		// Route must be checked out, not pristine and valid
		if (this.route.IsCheckedIn) {
			return false;
		}

		if (this.isPristine) {
			return false;
		}

		return this.isValid;
	}
	
	@computedFrom('route.IsCheckedIn', 'isPristine')
	get createCopyEnabled(): boolean {
		if (!this.route) {
			return false;
		}

		// Route must be checked in and pristine
		if (!this.route.IsCheckedIn) {
			return false;
		}

		return this.isPristine;
	}

	@computedFrom('isCommitter', 'route.IsCheckedIn', 'isPristine')
	get checkInEnabled(): boolean {
		// User must be a 'Committer'
		if (!this.isCommitter) {
			return false;
		}

		if (!this.route) {
			return false;
		}

		// Route must be checked out and pristine
		if (this.route.IsCheckedIn) {
			return false;
		}

		return this.isPristine;
	}

	@computedFrom('route.IsCheckedIn', 'isPristine')
	get checkOutEnabled(): boolean {
		if (!this.route) {
			return false;
		}

		// Route must be checked in and pristine
		if (!this.route.IsCheckedIn) {
			return false;
		}

		return this.isPristine;
	}

	@computedFrom('route.IsCheckedIn')
	get undoCheckOutEnabled(): boolean {
		if (!this.route) {
			return false;
		}

		// Route must be checked out and pristine
		if (this.route.IsCheckedIn) {
			return false;
		}

		return this.isPristine;
	}

	/**
	 * Style css for the scroll panel
	 */
	@computedFrom('adminNavPanel.scrollHeight', 'adminToolPanel.clientHeight')
	get scrollPanel(): string {
		if (!this.adminNavPanel) {
			return;
		}
		
		// clientHeight och offset top är samma som toolPanel, när det blir scroll på nav-panel så kommer det värdet vara högre och man sätter så att det blir scroll
		if (this.adminNavPanel.scrollHeight + this.adminNavPanel.offsetTop > this.adminToolPanel.clientHeight) {
			this.adminNavPanel.style = "overflow: auto";
			
			if (this.adminNavPanel.clientHeight != this.adminNavPanel.scrollHeight) {
				return "width: 458px;"
			}
		}
		else {
			this.adminNavPanel.style = "overflow: visible";
		}
	}

	@computedFrom('route.FeatureOid')
	get routeColor(): string{
		if (this.route && !this.route.IsCheckedIn) {
			return 'background-color: #F49F25 !important';
		}
		
		return 'background-color: #444 !important';
	}
	
	@computedFrom('route.NetworkReferences', 'route.Geometry')
	get routeToolsEnabled(): boolean {
		if (!this.route || !this.route.NetworkReferences || !this.route.Geometry) {
			return false;
		}

		return true;
	}

	constructor(
		private adminRouteService: AdminRouteService, 
		private dynamicBlockService: DynamicBlocksService,
		private toDosService: ToDosService, 
		private resolver: TneAsyncResolver, 
		private eventAggregator: EventAggregator, 
		private applicationRepository: ApplicationRepository,
		private dialogService: DialogService,
		private appAuthService: AppAuthService,
		private unvalidListUpdate: UnvalidFeatures ) {
			this.logger = LogManager.getLogger('Routing');
	}

	attached() {
		this.routeSettingsValidation = {
			isPristine: true,
			isValid: false
		};

		this.routeValidation = {
			isPristine: true,
			isValid: false
		};
		
		this.adminNavPanel = document.getElementById("adminNavPanel");
		this.adminToolPanel = document.getElementById("adminToolPanel");
		
		this.setFeatureLoadStartSubscription = this.eventAggregator.subscribe(AdminRouteEventItemType.SET_ROUTE_LOADING, () => {
			this.toolPanelVisible = true;
			this.loading = true;
			this.dynamicBlock = null;
		});

		this.setFeatureSubscription = this.eventAggregator.subscribe(AdminRouteEventItemType.SET_ROUTE, (route: IRoute) => {
			this.confirmDiscardChanges()
				.then((result: boolean) => {
					this.loading = false;

					if (!result) {
						// There are unsaved changes on current route and user did not want to discard
						return;
					}
					
					this.setRoute(route)
						.then(() => {
							this.toolPanelVisible = true;
							this.getPanels();
						});
				});
		});

		this.historySelectedSubscription = this.eventAggregator.subscribe(AdminHistoryEventItemType.RECREATE_SELECTED_HISTORY, (route) => {
			this.confirmDiscardChanges()
				.then((result: boolean) => {
					if (!result) {
						// There are unsaved changes on current route and user did not want to discard
						return;
					}

					const fromDate = this.route.ValidFrom;
					this.setRoute(route)
						.then(() => {
							this.route.ValidFrom = fromDate;
							this.toolPanelVisible = true;
							this.getPanels();
							this.saveRoute();
						});
				});
		});

		this.setFeatureLoadStartSubscription = this.eventAggregator.subscribe(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK_LOADING, () => {
			this.toolPanelVisible = true;
			this.loading = true;
			this.route = null;
		});

		this.setFeatureSubscription = this.eventAggregator.subscribe(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK, (dynamicBlock: IDynamicBlock) => {
			this.confirmDiscardChanges()
				.then((result: boolean) => {
					this.loading = false;

					if (!result) {
						return;
					}
					
					this.setDynamicBlock(dynamicBlock)
						.then(() => {
							this.toolPanelVisible = true;
							this.getPanels();
						});
				});
		});
		
		this.principal = this.appAuthService.getPrincipal();
	}

	detached() {
		if (this.setFeatureLoadStartSubscription) {
			this.setFeatureLoadStartSubscription.dispose();
		}

		if (this.setFeatureSubscription) {
			this.setFeatureSubscription.dispose();
		}

		if (this.historySelectedSubscription) {
			this.historySelectedSubscription.dispose();
		}
	}

	toggleRoute(): void {
		this.toolPanelVisible = !this.toolPanelVisible;
		this.getPanels();
	}

	routeTypeChanged($event: any): void {
		$event.stopPropagation();
		const routeType = +$event.detail.value;
		const viewDate = +this.applicationRepository.viewDate;
		this.route = this.adminRouteService.newRoute(routeType, viewDate);
		this.routeType = undefined; // Setting this to undefined makes it possible to select same route type again
	}

	dynamicblockTypeChanged($event: any): void{
		$event.stopPropagation();
		const dynamicblockType = +$event.detail.value;
		this.dynamicBlock = this.dynamicBlockService.newDynamicBlock(dynamicblockType);
		this.dynamicblockType = undefined;
	}

	checkOutRoute = async () => {
		this.loading = true;
		

		try {
			const checkOutRequest: ICheckOutRequest = {
				FeatureOid: this.route.FeatureOid,
				FeatureTypeId: this.route.FeatureTypeId,
				ViewDate: this.route.ValidTo > +this.applicationRepository.viewDate || this.route.ValidTo === null ? +this.applicationRepository.viewDate: this.route.ValidTo
			};

			const jobResponse = await this.adminRouteService.checkOutRoute(checkOutRequest);
			
			this.lastProgressText = undefined;
			const jobData = await this.resolver.execute(jobResponse.JobDefinitionId, this.jobProgressCallback, 2000);

			wait(1000)
				.then(() => {
					this.jobProgress = null;
				});

			if (jobData.Status === JobStatus.Completed) {

				const validTo = moment(this.route.ValidTo, 'YYYYMMDD');
				validTo.subtract(1, 'days');
				const viewDate = this.route.ValidTo != 99991231 && this.route.ValidTo != null ? validTo.format('YYYYMMDD') : this.applicationRepository.viewDate;
				const route = await this.adminRouteService.getCheckedOutRoute(jobResponse.FeatureOid, this.route.RouteType, viewDate);
				await this.setRoute(route);
				
				// Update status in 'Jobblistan' and trigger events to reload data
				this.tryUpdateToDoStatus(this.route.FeatureOid, ToDoStatus.CheckedOut);

				this.loading = false;
			}
			else {
				this.loading = false;
			}
		}
		catch (error) {
			this.loading = false;
			this.jobProgress = null;
		}
	}
	
	restoreRoute = async () => {
		this.confirmDiscardChanges()
			.then(async (result: boolean) => {
				this.loading = false;

				if (!result) {
					// There are unsaved changes on current route and user did not want to discard
					return;
				}
				const validTo = moment(this.route.ValidTo, 'YYYYMMDD');
				validTo.subtract(1, 'days');
				const viewDate = this.route.ValidTo != 99991231 && this.route.ValidTo != null ? validTo.format('YYYYMMDD') : this.applicationRepository.viewDate;
				const route = await this.adminRouteService.getCheckedOutRoute(this.route.FeatureOid, this.route.RouteType, viewDate);
				this.setRoute(route)
					.then(() => {
						this.toolPanelVisible = true;
						this.getPanels();
					});
			});
	}

	checkOutRouteDialog() {
		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du checka ut leden?",
			ExtraInfo: "Leden går fortfarande att rutta på i produktion.",
			AbortText: "Avbryt",
			ConfirmText: "Checka ut"
		};

		this.dialogService.open({ viewModel: Alert, model: alertModel }).whenClosed(response => {
			if (!response.wasCancelled) {
				this.checkOutRoute();
			}
		});
	}

	checkInRouteDialog() {
		// User needs to be in the role VV_COMMITTER in order to check in a route
		if (!this.isCommitter) {
			const notAuthModel: AlertModel = {
				Mode: "error",
				ErrorMessage: "Du saknar behörighet för att checka in leder"
			};

			this.dialogService.open({viewModel: Alert, model: notAuthModel});
			return
		}

		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du checka in leden?",
			ExtraInfo: "Leden aktiveras och används i produktion.",
			AbortText: "Avbryt",
			ConfirmText: "Checka in"
		};

		this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			if (!response.wasCancelled) {
				this.checkInRoute();
			}
		});
	}

	saveRoute = async () => {
		this.loading = true;

		try {
			const jobResponse = await this.adminRouteService.saveRoute(this.route);
			this.lastProgressText = undefined;
			const jobData = await this.resolver.execute(jobResponse.JobDefinitionId, this.jobProgressCallback, 2000);

			wait(1000)
				.then(() => {
					this.jobProgress = null;
				});

			if (jobData.Status === JobStatus.Completed) {

				const validTo = moment(this.route.ValidTo, 'YYYYMMDD');
				validTo.subtract(1, 'days');
				const viewDate = this.route.ValidTo != 99991231 && this.route.ValidTo != null ? validTo.format('YYYYMMDD') : this.applicationRepository.viewDate;
				const route = await this.adminRouteService.getCheckedOutRoute(jobResponse.FeatureOid, this.route.RouteType, viewDate);
				
				this.eventAggregator.publish(AdminCheckedOutEventItemType.RELOAD);

				await this.setRoute(route);
				this.loading = false;
			}
			else {
				this.loading = false;
			}
		}
		catch (error) {
			this.loading = false;
			this.jobProgress = null;
		}
	}
	
	copyRoute() {
		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du kopiera leden?",
			AbortText: "Avbryt",
			ConfirmText: "Skapa kopia"
		};

		this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			if (!response.wasCancelled) {
				this.route.FeatureOid = null;
				this.route.RouteState = RouteState.Utcheckad;
				this.saveRoute();
			}
		});
	}

	undoCheckOutRouteDialog() {
		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du ångra utcheckning?",
			ExtraInfo: "Leden tas bort ur 'utcheckade leder'",
			AbortText: "Avbryt",
			ConfirmText: "Ångra utcheckning"
		};

		this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			if (!response.wasCancelled) {
				this.undoCheckOutRoute();
			}
		});
	}

	deleteRouteDialog() {
		// User needs to be in the role VV_COMMITTER in order to delete a route
		if (!this.isCommitter) {
			const notAuthModel: AlertModel = {
				Mode: "error",
				ErrorMessage: "Du saknar behörighet för att ta bort leder"
				
			};

			this.dialogService.open({viewModel: Alert, model: notAuthModel});
			return
		}

		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du ta bort leden?",
			ExtraInfo: "Leden tas bort ur produktion, operationen kan inte ångras.",
			AbortText: "Avbryt",
			ConfirmText: "Ta bort led",
			ConfirmButtonColor: "red"
		};

		this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			if (!response.wasCancelled) {
				this.deleteRoute();
			}
		});
	}

	endRouteDialog() {
		// User needs to be in the role VV_COMMITTER in order to end a route
		if (!this.isCommitter) {
			const notAuthModel: AlertModel = {
				Mode: "error",
				ErrorMessage: "Du saknar behörighet för att avsluta leder"
				
			};

			this.dialogService.open({viewModel: Alert, model: notAuthModel});
			return
		}

		if((this.applicationRepository.viewDate == this.route.ValidFrom.toString()) || this.route.ValidFrom > parseInt(this.applicationRepository.viewDate)){
			const endErrorModel: AlertModel = {
				Mode: "error",
				ErrorMessage: "Det går inte att avsluta leden!",
				ExtraInfo: "Leden måste ha ett giltigt från datum som är tidigare än betrakelsedatumet." 
			};
			this.dialogService.open({viewModel: Alert, model: endErrorModel});
			return
		}

		else{
			const date = moment(this.applicationRepository.viewDate);
			const alertModel: AlertModel = {
				Mode: "question",
				ErrorMessage: "Vill du avsluta leden?",
				ExtraInfo: "Rutten kommer att vara giltig till " + date.format("YYYY-MM-DD") + '\n' + "Checka in leden för att verkställa.",
				AbortText: "Avbryt",
				ConfirmText: "Avsluta led",
				ConfirmButtonColor: "red"
			};

			this.dialogService.open({ viewModel: Alert, model: alertModel }).whenClosed(response => {
				if (!response.wasCancelled) {
					this.endRoute();
				}
			});
		}
	}

	toggleShowRoute() {
		this.showRoute = !this.showRoute;
		this.eventAggregator.publish(AdminRouteEventItemType.TOGGLE_SHOW_ROUTE, this.showRoute);
	}

	zoomToRoute() {
		this.eventAggregator.publish(AdminRouteEventItemType.ZOOM_TO_ROUTE);
	}

	async closeRoute(event) {
		event.stopPropagation();

		const okToClose = await this.confirmDiscardChanges();

		if (okToClose) {
			this.toolPanelVisible = false;
			this.route = null;
			this.dynamicBlock = null;
		}
	}
	
	/**
	 * Sets this.route wrapped in a timeout
	 * @param route 
	 */
	private async setRoute(route: IRoute): Promise<void> {
		this.route = null;
		
		await wait(10);

		this.showRoute = true;
		this.route = JSON.parse(JSON.stringify(route));
	}
	
	private setStatusToActive = async (): Promise<number> => {
		try {
			return await this.adminRouteService.setStatusToActiveRoute(this.route);
		}
		catch (error) {
			this.loading = false;
			this.jobProgress = null;
		}
	}

	private jobProgressCallback: ProgressCallback = (res: IJobInfoData) => {
		if (res.ProgressText !== this.lastProgressText) {
			this.lastProgressText = res.ProgressText;
			this.jobProgress = {
				progress: res.Progress,
				progressText: res.ProgressText
			};
		}
	}

	private async tryUpdateToDoStatus(featureOid: string, status: ToDoStatus) {
		try {
			await this.toDosService.updateStatus(featureOid, status);
			this.eventAggregator.publish(AdminToDoEventItemType.RELOAD);
			this.eventAggregator.publish(AdminCheckedOutEventItemType.RELOAD);

			// Will merge new params to wms layers and trigger reload
			this.eventAggregator.publish(LayerEventType.INVALIDATE_CACHE);
		}
		catch (error) {
			this.logger.error(error);
		}
	}

	private async confirmDiscardChanges(): Promise<boolean> {
		if (!this.route) {
			return true;
		}

		if(!this.dynamicBlock){
			return true;
		}
		
		if (this.isPristine) {
			return true;
		}

		const promise = new Promise<boolean>((resolve) => {
			const alertModel: AlertModel = {
				Mode: "confirm",
				ErrorMessage: "Du har osparade ändringar",
				ExtraInfo: "Ändringarna går förlorade om du går vidare utan att spara.",
				AbortText: "Avbryt",
				ConfirmText: "Gå vidare utan att spara",
				ConfirmButtonColor: "red"
			};
	
			this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
				return resolve(!response.wasCancelled);
			});
		});

		return promise;
	}


	/**
	 * Three jobs in sequence (check-in, set statusToActive, then tryUpdateToDoStatus)
	 */
	private checkInRoute = async () => {
		this.loading = true;

		try {
			const jobDefinitionId = await this.adminRouteService.checkInRoute(this.route);
			this.lastProgressText = undefined;
			const jobData = await this.resolver.execute(jobDefinitionId, this.jobProgressCallback, 2000);

			wait(1000)
				.then(() => {
					this.jobProgress = null;
				});

			if (jobData.Status === JobStatus.Completed) {
				const setActiveJobDefId = await this.setStatusToActive();
				this.lastProgressText = undefined;
				const activeJobData = await this.resolver.execute(setActiveJobDefId, this.jobProgressCallback, 2000);
				
				wait(1000)
					.then(() => {
						this.jobProgress = null;
					});

				if (activeJobData.Status === JobStatus.Completed) {
					const validTo = moment(this.route.ValidTo, 'YYYYMMDD');
					validTo.subtract(1, 'days');
					const viewDate = this.route.ValidTo != 99991231 && this.route.ValidTo != null ? validTo.format('YYYYMMDD') : this.applicationRepository.viewDate;
					const route = await this.adminRouteService.getCheckedInRoute(this.route.FeatureOid, this.route.RouteType, viewDate);
					// Update status in 'Jobblistan' and trigger events to reload data
					await this.tryUpdateToDoStatus(this.route.FeatureOid, ToDoStatus.Active);
					await this.setRoute(route);

					this.loading = false;
				}
				else {
					this.loading = false;
				}
			}
			else {
				this.loading = false;
			}

		}
		catch (error) {
			this.loading = false;
			this.jobProgress = null;
		}
	}

	private undoCheckOutRoute = async () => {
		this.loading = true;

		try {
			const jobDefinitionId = await this.adminRouteService.undoCheckOut(this.route);

			this.lastProgressText = undefined;
			const jobData = await this.resolver.execute(jobDefinitionId, this.jobProgressCallback, 2000);

			wait(1000)
				.then(() => {
					this.jobProgress = null;
				});

			if (jobData.Status === JobStatus.Completed) {
				// Update status in 'Jobblistan' and trigger events to reload data (route can not be set to null before this call)
				await this.tryUpdateToDoStatus(this.route.FeatureOid, ToDoStatus.New);
				const validTo = moment(this.route.ValidTo, 'YYYYMMDD');
				validTo.subtract(1, 'days');
				const viewDate = this.route.ValidTo != 99991231 && this.route.ValidTo != null ? validTo.format('YYYYMMDD') : this.applicationRepository.viewDate;

				const route = await this.adminRouteService.getCheckedInRoute(this.route.FeatureOid, this.route.RouteType, viewDate);
				await this.setRoute(route);
				this.loading = false;
				
				// undoCheckOut can result in a null route if it has not been checked in before
				if (!this.route) {
					this.toolPanelVisible = false;
				}
			}
		}
		catch (error) {
			this.loading = false;
			this.jobProgress = null;
		}
	}

	private deleteRoute = async () => {
		this.loading = true;

		try {
			const jobDefinitionId = await this.adminRouteService.deleteRoute(this.route);

			this.lastProgressText = undefined;
			const jobData = await this.resolver.execute(jobDefinitionId, this.jobProgressCallback, 2000);

			wait(1000)
				.then(() => {
					this.jobProgress = null;
				});

			if (jobData.Status === JobStatus.Completed) {
				await this.tryUpdateToDoStatus(this.route.FeatureOid, ToDoStatus.Active);
				await this.setRoute(null);
				this.loading = false;
				this.toolPanelVisible = false;
			}
		}
		catch (error) {
			this.loading = false;
			this.jobProgress = null;
		}
	}

	private endRoute = async () =>{
		this.loading = true;
		try {

			this.route.ValidTo = parseInt(this.applicationRepository.viewDate);
			const jobResponse = await this.adminRouteService.saveRoute(this.route);

			this.lastProgressText = undefined;
			const jobData = await this.resolver.execute(jobResponse.JobDefinitionId, this.jobProgressCallback, 2000);

			wait(1000)
				.then(() => {
					this.jobProgress = null;
				});

			if (jobData.Status === JobStatus.Completed) {

				const validTo = moment(this.route.ValidTo, 'YYYYMMDD');
				validTo.subtract(1, 'days');
				const viewDate = this.route.ValidTo != 99991231 && this.route.ValidTo != null ? validTo.format('YYYYMMDD') : this.applicationRepository.viewDate;
				const route = await this.adminRouteService.getCheckedOutRoute(jobResponse.FeatureOid, this.route.RouteType, viewDate);
				
				this.eventAggregator.publish(AdminCheckedOutEventItemType.RELOAD);

				await this.setRoute(route);
				this.loading = false;
			}
			else {
				this.loading = false;
			}
		}
		catch (error) {
			this.loading = false;
			this.jobProgress = null;
		}
	}

	private async setDynamicBlock(dynamicBlock: IDynamicBlock): Promise<void> {
		this.dynamicBlock = null;
		
		await wait(10);

		this.dynamicBlock = JSON.parse(JSON.stringify(dynamicBlock));
	}
	/**
	 * Do not know what happens here
	 */
	private async getPanels() {
		await wait(500);
		if (!this.adminNavPanel) {
			this.adminNavPanel = document.getElementById("adminNavPanel");
		}

		if (!this.adminToolPanel) {
			this.adminToolPanel = document.getElementById("adminToolPanel");
		}
	}
}
