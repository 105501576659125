import { autoinject, bindable, bindingMode, computedFrom, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';

import moment from "moment";

import { RouteState, RouteType } from 'components/admin/admin-constants/index';
import { wait } from 'components/admin/services/utilities/wait';
import { IRoute } from 'components/admin/services/admin-route/models/route-interface';
import { Direction } from 'components/admin/services/admin-route/models/direction.enum';
import { ITPRoute } from 'components/admin/services/admin-route/models/tp-route.interface';
import { IFacitRoute } from 'components/admin/services/admin-route/models/facit-route.interface';
import { IRekRoute } from 'components/admin/services/admin-route/models/rek-route.interface';
import { ITimberRoute } from 'components/admin/services/admin-route/models/timber-route.interface';

@autoinject()
export class RouteSettings {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) route: IRoute;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) validation: { isPristine: boolean; isValid: boolean };

	toDatePlaceholder = 'Tillsvidare';
	toDateMinDateValue: number;
	expanded = true;
	minWeight: number;
	maxWeight: number;


    routeType = {
        FacitRutt: RouteType.FacitRutt,
        TpLed: RouteType.TpLed,
        TimmerLed: RouteType.TimmerLed,
        RekommenderadLed: RouteType.RekommenderadLed
    }

    routeTypes = [
        {
            id: RouteType.FacitRutt,
            name: 'Facitrutt'
        }, {
            id: RouteType.RekommenderadLed,
            name: 'Rekommenderad led'
        }, {
            id: RouteType.TimmerLed,
            name: 'Timmerled'
        }, {
            id: RouteType.TpLed,
            name: 'TP-led'
        }
    ];

    routeStates = [
        {
            id: RouteState.Utcheckad,
            name: 'Arbetsversion'
        }, {
            id: RouteState.Forslag,
            name: 'Förslag'
        }
    ];

    directions = [
        {
            id: Direction.In,
            name: 'In'
        }, {
            id: Direction.InOchUt,
            name: 'In och ut'
        }, {
            id: Direction.Ut,
            name: 'Ut'
        }
    ];

	vehicleDefinitions: number[] = [20, 37, 51.4, 64, 74];

	private logger: Logger;
	private detectChanges: boolean;

	constructor() {
		this.logger = LogManager.getLogger('RouteSettings');
		// Set minweight to first value of vehicleDefinitions and max to last. 
		this.minWeight = this.vehicleDefinitions[0];
		this.maxWeight = this.vehicleDefinitions[this.vehicleDefinitions.length-1];
	}

	bind() {
		// This code can not be executed in attached since it is to late to bind to the toDateMinDateValue
		if (this.route.FeatureOid && !this.route.IsCheckedIn) {
			// Get min valid toDate (+1 day from ValidFrom)
			const minValidTo = moment(this.route.ValidFrom, 'YYYYMMDD');
			minValidTo.add(1, 'days');
			this.toDateMinDateValue = +minValidTo.format('YYYYMMDD');
		}

		if(this.route.RouteType == this.routeType.TpLed) {
			// Deafult values for min and max weight
			if(this.route.Id == null){
				(this.route as ITPRoute).MinWeight = this.minWeight;
				(this.route as ITPRoute).MaxWeight = this.maxWeight;
				(this.route as ITPRoute).Direction = this.directions[0].id.toString();
			}
		}
	}

	attached() {
		// Disabling change tracking for a moment
		this.detectChanges = false;

		// Default validation state
		this.validation = {
			isPristine: true,
			isValid: false
		};

		// Trigger a validation
		this.formIsValid;

		// Delay half a second before avtivating change tracking
		wait(500).then(() => {
			this.detectChanges = true;
		});
	}

    @computedFrom('route.RouteState')
    get hasRouteState(): boolean {
        if (!this.route || this.route.RouteState == undefined || this.route.RouteState == null) {
            return false;
        }

        return true;
    }

    @computedFrom('route.Direction')
    get hasDirection(): boolean {
        if (!this.route || (this.route as ITPRoute).Direction == undefined || (this.route as ITPRoute).Direction == null) {
            return false;
        }

        return true;
    }

    @computedFrom('route.RouteType')
    get routeTypeName(): string {
        if (!this.route || this.route.RouteType == undefined || this.route.RouteType == null) {
            return '';
        }

        return this.routeTypes.find(x => x.id === this.route.RouteType).name;
    }

    @computedFrom('route.RouteType', 'route.RouteState', 'route.ValidFrom', 'route.ValidTo', 'route.Comment', 'facitRouteIsValid', 'rekRouteIsValid', 'timberRouteIsValid', 'tpRouteIsValid')
    get formIsValid(): boolean {
		if (this.detectChanges) {
			this.validation.isPristine = false;
		}

		if (!this.route) {
            this.validation.isValid = false;
            return false;
		}

        // Common
        if (this.route.RouteType === undefined || this.route.RouteType === null) {
			this.validation.isValid = false;
            return false;
        }

        if (this.route.RouteState === undefined || this.route.RouteState === null) {
			this.validation.isValid = false;
            return false;
        }

		if (this.route.ValidTo && this.route.ValidTo <= this.route.ValidFrom) {
			// If specified -> must be greater than ValidFrom
			this.validation.isValid = false;
            return false;
		}

		// Typed
		if (!this.facitRouteIsValid) {
			this.validation.isValid = false;
            return false;
		}

		if (!this.rekRouteIsValid) {
			this.validation.isValid = false;
            return false;
		}

		if (!this.timberRouteIsValid) {
			this.validation.isValid = false;
            return false;
		}

		if (!this.tpRouteIsValid) {
			this.validation.isValid = false;
            return false;
		}

		this.validation.isValid = true;

        return true;
    }

    toggle() {
        this.expanded = !this.expanded;

        this.logger.debug('Expanded: ', this.expanded);
	}

	dateChanged(event: CustomEvent){
		if (!event.detail) {
			this.route.ValidTo = null;
			return;
		}

		const date = (event.detail as Date);
		this.route.ValidTo = +moment(date).format('YYYYMMDD');
	}
    
    @computedFrom('route.Charterer', 'route.Name')
    private get facitRouteIsValid(): boolean {
		if (!this.route) {
			return false;
		}

        if (this.route.RouteType !== RouteType.FacitRutt) {
            return true;
		}

		if (this.detectChanges) {
			this.validation.isPristine = false;
		}
        
        const routeAsFacit = (this.route as IFacitRoute)

        if (!routeAsFacit.Charterer) {
            this.validation.isValid = false;
            return false;
        }

        if (!routeAsFacit.Name) {
            this.validation.isValid = false;
            return false;
        }

        return true;
    }

    @computedFrom('route.Name')
    private get rekRouteIsValid(): boolean {
		if (!this.route) {
			return false;
		}

        if (this.route.RouteType !== RouteType.RekommenderadLed) {
            return true;
		}
		
		if (this.detectChanges) {
			this.validation.isPristine = false;
		}
        
        const routeAsRek = (this.route as IRekRoute)

        if (!routeAsRek.Name) {
            this.validation.isValid = false;
            return false;
        }

        return true;
	}

	@computedFrom('route.Name')
    private get timberRouteIsValid(): boolean {
		if (!this.route) {
			return false;
		}

        if (this.route.RouteType !== RouteType.TimmerLed) {
            return true;
		}
		
		if (this.detectChanges) {
			this.validation.isPristine = false;
		}
        
        const routeAsRek = (this.route as ITimberRoute)

        if (!routeAsRek.Name) {
            this.validation.isValid = false;
            return false;
        }

        return true;
	}

	@computedFrom('route.Direction', 'route.MinWeight', 'route.MaxWeight')
    private get tpRouteIsValid(): boolean {
		if (!this.route) {
			return false;
		}
	
        if (this.route.RouteType !== RouteType.TpLed) {
            return true;
		}

		if (this.detectChanges) {
			this.validation.isPristine = false;
		}
       
		const routeAsTp = (this.route as ITPRoute)
	
		if (!routeAsTp.Direction) {
			this.validation.isValid = false;
            return false;
		}

        return true;
	}
}

export class RouteWeightFormatValueConverter {
    toView(value: number) {
        if (value === 0) {
            return "";
		}
		else{
			return value;
		}
      }
}
