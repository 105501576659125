import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';

import * as L from 'leaflet';

import { ApplicationRepository } from "services/application-repository/application-repository";
import { SearchType } from "services/place-search/models/search-type.enum";
import { PlaceSearchService } from "services/place-search/place-search-service";
import { IPlaceSearchItem } from "services/place-search/models/place-search-item.interface";
import { IMapRoutePoint } from "services/application-repository/models/map-route-point.interface";
import { ITPRoute } from "components/admin/services/admin-route/models/tp-route.interface";
import { IconService } from "services/assets/services/icon.service";
import { IconType } from "services/assets/enums/icon-type.enum";

@autoinject()
export class RouteSites{
	expanded = true;
    private siteLocations: IMapRoutePoint[] = [];
    private siteMarkers: L.Marker[] = [];
    private map: L.Map;
	private mapCrs;
	private searchItemSub;
	private idx = null; 
	@bindable({ defaultBindingMode: bindingMode.twoWay }) route: ITPRoute;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) isPristine: boolean;

    constructor(
		private applicationRepo: ApplicationRepository, 
		private placeSearchService: PlaceSearchService,
		private eventAggregator: EventAggregator,
		private iconService: IconService) {
		this.siteLocations = null;
		this.siteMarkers = [];
		
		this.searchItemSub = this.eventAggregator.subscribe("searchItemField", payload => {
			if(payload.searchItem.id){
				this.addSiteLocationByMotPlats(payload.searchItem);
			}
		});
	}
	
    attached() {
		this.map = this.applicationRepo.map;
		this.mapCrs = this.applicationRepo.mapCrs;
		
		this.siteLocations = [];
		this.removeSiteMarkers();
		
		if (this.route && this.route.PickupLocationNumbers) {
			this.setSiteLocations();
		} 
		else {
			this.siteLocations.push(this.getEmptyMapRoutePoint());
		}

		this.isPristine = true;
		
		
        
	}
	
    detached() {
        this.siteLocations = null;
        this.removeSiteMarkers();
    }

	toggle() {
        this.expanded = !this.expanded;
    }

    addSiteLocation() {
		this.isPristine = false;
        if (!this.siteLocations) {
            this.siteLocations = [];
        }
    
        if (this.siteLocations.length > 0 &&
            this.siteLocations[this.siteLocations.length - 1] == null) {
            return;
        }
    
        if (this.siteLocations[this.siteLocations.length - 1] &&
            !this.siteLocations[this.siteLocations.length - 1].name) {
            return;
        }
        this.siteLocations.push(this.getEmptyMapRoutePoint());
    }

    search = (s: string) => {
        return this.placeSearchService.getSuggestions(s, [SearchType.SITE]);
    }

    filterLastSearches = (items: IPlaceSearchItem[]): IPlaceSearchItem[] => {
        const filtered = items.filter(x => x.type === SearchType.SITE);
        return filtered;
    }

    filterFavorites = (items: IPlaceSearchItem[]): IPlaceSearchItem[] => {
        const filtered = items.filter(x => x.type === SearchType.SITE);
        return filtered;
    }

    formatItem = (searchItem: IPlaceSearchItem) => {
        return this.placeSearchService.formatItem(searchItem);
    }
	
	addSiteLocationByMotPlats(site){
		if(!this.route.PickupLocationNumbers){
			this.route.PickupLocationNumbers = [];
		}
		if(!this.siteLocations){
			this.siteLocations = [];
		}
		const lastSiteLocationIsEmpty = () => {
			if (!this.siteLocations || this.siteLocations.length === 0) {
				return false;
			}

			if (!this.siteLocations[this.siteLocations.length - 1] ||
				!this.siteLocations[this.siteLocations.length - 1].name) {
				return true;
			}

			return false;
		};

		// Check if siteLocations contains site to avoid duplicates
		if(this.siteLocations.length > 0 && this.siteLocations != null){
			for(let i = 0; i<this.siteLocations.length; i++){
				if(this.siteLocations[i].id == site.id){
					return;
				}
			}
		}

		if (this.idx == null) {
			if (lastSiteLocationIsEmpty()) {
				this.siteLocations.unshift(site);
				this.route.PickupLocationNumbers.unshift(site.id);
			}
			else {
				this.route.PickupLocationNumbers.push(site.id)
				this.siteLocations.push(site);
			}
		}
		else {
			this.siteLocations.unshift(site);
			this.route.PickupLocationNumbers.unshift(site.id);
		}

		this.showSitesInMap();

		if(this.siteLocations == null){
			this.siteLocations.push(this.getEmptyMapRoutePoint());
		}
		this.idx++;
		
	}
	
    siteLocationSelected(event: any, index: number) {
		// Check if siteLocations contains site to avoid duplicates
		if(this.siteLocations.length > 0 && this.siteLocations != null){
			for(let i = 0; i<this.siteLocations.length; i++){
				if(!this.siteLocations[i]){
					this.siteLocations[i] = this.getEmptyMapRoutePoint();
				}
				if(this.siteLocations[i].id == event.detail.id){
					return;
				}
			}
		}

		this.isPristine = false;
		this.idx++;
        if (!this.route.PickupLocationNumbers) {
            this.route.PickupLocationNumbers = [];
		}
		
        let searchItem;
        if (event.detail) {
            searchItem = event.detail as IMapRoutePoint;
        }
        else {
            searchItem = event as IMapRoutePoint;
        }
    
        if (searchItem.name != null) {
            const lastSiteLocationIsEmpty = () => {
                if (!this.siteLocations || this.siteLocations.length === 0) {
                    return false;
                }
    
                if (!this.siteLocations[this.siteLocations.length - 1] ||
                    !this.siteLocations[this.siteLocations.length - 1].name) {
                    return true;
                }
    
                return false;
			};
			
            if (index == null) {
                if (lastSiteLocationIsEmpty()) {
                    this.siteLocations.splice(this.siteLocations.length - 1, 1, searchItem);
                    this.route.PickupLocationNumbers.splice(this.route.PickupLocationNumbers.length - 1, 1, searchItem.id);
                }
                else {
                    this.route.PickupLocationNumbers.push(searchItem.id)
                    this.siteLocations.push(searchItem);
                }
            }
            else {
                this.siteLocations.splice(index, 1, searchItem);
                this.route.PickupLocationNumbers.splice(index, 1, searchItem.id);
			}
			
            this.showSitesInMap();
        }
    }
    
    removeSite(index) {
		this.isPristine = false;
		this.idx--;
		if(this.idx == -1){
			this.idx++;
		}
		if (index == null) {
			this.siteLocations = [];
			this.route.PickupLocationNumbers = [];
		}
		else {
			this.siteLocations.splice(index, 1);
			// If PickupLocationsNumbers is null, there is nothing to splice. 
			if(this.route.PickupLocationNumbers != null){
				this.route.PickupLocationNumbers.splice(index, 1);
			}
        }
        
        this.removeSiteMarkers();
		this.showSitesInMap();
    }

	/**
	 * Initial site locations from route
	 */
    private async setSiteLocations(){
        this.siteLocations = [];
         
        for (const item of this.route.PickupLocationNumbers) {
            const site = await this.placeSearchService.getSuggestions(item, [SearchType.SITE]);
            if (site.length > 0) {
                this.siteLocations.push(site[0] as IMapRoutePoint)
            }
            
            if (!this.route.IsCheckedIn) {
                this.showSitesInMap();
            }
        }
    }

    private showSitesInMap(){
        if (this.siteLocations != null) {
            this.removeSiteMarkers();
            for (let i = 0; i < this.siteLocations.length; i++) {
                if (this.siteLocations[i] != null && this.siteLocations[i].name != null) {
                    const latlng = this.mapCrs.unproject({ x: this.siteLocations[i].easting, y: this.siteLocations[i].northing });
                    const siteIcon = L.icon({
                        iconUrl: this.iconService.getIcon(IconType.IconKoppladPlats),
                        iconSize: [26, 38],
                        iconAnchor: [13, 38]
                    });

                    const siteMarker = L.marker(latlng, { icon: siteIcon }).addTo(this.map);

                    this.siteMarkers.push(siteMarker);
                }
            }
        }
    }

    private removeSiteMarkers(): void {
        if (this.siteMarkers) {
			this.siteMarkers.forEach(marker => {
				marker.remove();
			});
            this.siteMarkers = [];
        }
    }
    
    private getEmptyMapRoutePoint(): IMapRoutePoint {
		return {
			id: null,
			type: null,
			name: null,
			latlng: null,
			projected: null
		};
	}
}
